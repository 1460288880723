import React from "react";
import { useState } from "react";
// import Navbar from "../components/Navbar";
import img from "../image/card1.png";
// import { GoArrowLeft } from "react-icons/go";
import { MdLocationOn } from "react-icons/md";
import image1 from "../image/image1.png";
import "./PaymentCard.css";
import { Link } from "react-router-dom";
// import "./payment.css";

function ConfirmCharger() {
  const [isChecked, setIsChecked] = useState(false);

  const handleStartCharging = () => {
    if (isChecked) {
      // Proceed with charging logic here
      console.log("Starting charging...");
    } else {
      // Show error or highlight the checkbox
    }
  };

  console.log(handleStartCharging());

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };
  return (
    <div className="max-w-md mx-auto bg-white rounded-lg shadow-md overflow-hidden">
      <header className="sticky-header fixed top-0 w-full h-[64px] gap-4 bg-accent-5 text-white flex items-center justify-center z-50">
        <h1 className="text-xl w-full text-center font-bold pl-5">
          Enter Payment Details
        </h1>
        <img className="h-6 w-[10%] mr-4" src={image1} alt="logo" />
      </header>
      <div
        className="bg-blue-600 text-white p-4 rounded-b-2xl text-center mt-[64px]"
        style={{ backgroundColor: "#0244CA" }}
      >
        <div className="flex md:items-center justify-center">
          <MdLocationOn className="LogoLocation text-2xl ml-1 mr-2 md:text-center" />
          <h2 className="text-[14px] font-bold headerId">
            Station ID - UZX67CH0943
          </h2>
        </div>
        <p className="text-[12px] location">
          21 Avenue, Metropolitan Mall, Downtown, New York City
        </p>
      </div>

      <div className="p-6">
        <div className="flex justify-center">
          <img className="h-28 w-28" src={img} alt="Connector" />
        </div>
        <div className="text-center mt-4">
          <h1 className="text-xl font-bold">J1772</h1>
          <p className="text-lg">$0.44/kWh</p>
          <button className="bg-green-500 text-white rounded-lg px-4 py-1 mt-2">
            Slot 1: AC - 44KW
          </button>
        </div>
        <div className="mt-6 text-center">
          <p className="text-lg font-bold">Clark Kent</p>
          <p className="text-md">clark.kent@gmail.com</p>
          <p className="text-md">+1 237 449 8744</p>
          <p className="text-sm text-gray-600">SMS Notification</p>
          <p className="text-lg font-bold mt-4">Payment Method: 9033</p>
        </div>
      </div>
      <div className="p-6 border-t">
        <div className="flex items-center">
          <input
            type="checkbox"
            className="h-4 w-4 text-blue-500 focus:ring-blue-400 rounded-lg"
          />
          <label className="ml-2 text-sm">
            I Agree to the <span className="text-blue-500">Terms of Use</span>{" "}
            and <span className="text-blue-500">Privacy Policy</span>
          </label>
        </div>
        <p className="text-center mt-4 font-bold text-sm">
          Please confirm if the connector is plugged in before proceeding.
        </p>
        <Link to={"/charging"}>
          <button
            className="mt-4 bg-blue-600 text-white w-full py-2 rounded-md"
            onClick={handleCheckboxChange}
          >
            Start Charging
          </button>
        </Link>
      </div>
    </div>
  );
}

export default ConfirmCharger;
