import React, { useState } from "react";
import image1 from "../image/image1.png";

import image from "../image/image.png";
import logo from "../image/logo.png";
import logo2 from "../image/logo2.png";
import logo3 from "../image/logo3.png";
import logo4 from "../image/logo4.png";
import ModalComponent from "../components/ModalComponent";
import img1 from "../image/card1.png";
import { IoLocationSharp } from "react-icons/io5";
// import Connector from "../components/Connector";

const Home = () => {
  const [connectors] = useState([
    {
      img1: "./image/card1.png",
      type: "JT772",
      price: "0.44 / kWh",
      slot: "Slot 1: AC: 44.4 kW",
      sessionFee: "0.98",
      sessionFee1: "Session Fee",
      parkingFee: "Parking Fee  1.05",
      available: true,
      status: "avalable",
    },
    {
      img1: "./image/card1.png",
      type: "JT772",
      price: "0.44 / kWh",
      slot: "Slot 1: AC: 44.4 kW",
      sessionFee: "0.98",
      sessionFee1: "Session Fee",
      parkingFee: "Parking Fee  1.05",
      available: true,
      status: "avalable",
    },
    {
      img1: "./image/card1.png",
      type: "JT772",
      price: "0.44 / kWh",
      slot: "Slot 1: AC: 44.4 kW",
      sessionFee: "0.98",
      sessionFee1: "Session Fee",
      parkingFee: "Parking Fee  1.05",
      available: true,
      status: "avalable",
    },
    {
      img1: "./image/card1.png",
      type: "JT772",
      price: "0.44 / kWh",
      slot: "Slot 1: AC: 44.4 kW",
      sessionFee: "0.98",
      sessionFee1: "Session Fee",
      parkingFee: "Parking Fee  1.05",
      available: false,
      status: "In use",
    },
    {
      logo: "./image/logo5.png",
      img1: "./image/card2.png",
      type: "CCS2",
      price: "0.44 / kWh",
      slot: "Slot 2: DC: 44.4 kW",
      sessionFee: "0.98",
      sessionFee1: "Session Fee",
      parkingFee: "Parking Fee  1.25",
      available: true,
      status: "Fast charger",
    },
    {
      logo: "./image/logo5.png",
      img1: "./image/card2.png",
      type: "CCS2",
      price: "0.44 / kWh",
      slot: "Slot 2: DC: 44.4 kW",
      sessionFee: "0.98",
      sessionFee1: "Session Fee",
      parkingFee: "Parking Fee  1.25",
      available: false,
      status: "In use",
    },
    {
      logo: "./image/logo5.png",
      img1: "./image/card2.png",
      type: "CCS2",
      price: "0.44 / kWh",
      slot: "Slot 2: DC: 44.4 kW",
      sessionFee: "0.98",
      sessionFee1: "Session Fee",
      parkingFee: "Parking Fee  1.25",
      available: false,
      status: "Faulted",
    },
    {
      logo: "./image/logo5.png",
      img1: "./image/card2.png",
      type: "CCS2",
      price: "0.44 / kWh",
      slot: "Slot 2: DC: 44.4 kW",
      sessionFee: "0.98",
      sessionFee1: "Session Fee",
      parkingFee: "Parking Fee  1.25",
      available: false,
      status: "In use",
    },
  ]);

  const [selectedConnector, setSelectedConnector] = useState(null);

  const openModal = (connector) => {
    setSelectedConnector(connector);
    document.body.classList.add("overflow-y-hidden");
  };

  const closeModal = () => {
    setSelectedConnector(null);
    document.body.classList.remove("overflow-y-hidden");
  };

  return (
    <>
    <header className="sticky-header fixed top-0 w-full h-[64px] gap-4 bg-accent-5 text-white flex items-center justify-center z-50">
      <h1 className="text-2xl w-full text-center font-bold pl-5">
        Select a Connector
      </h1>
      <img className="h-6 w-[10%] mr-4" src={image1} alt="logo" />
    </header>
      <div
        className="fixed mt-[64px]"
        style={
          {
            //  backgroundImage: "../image/image.png",
          }
        }
      >
        <img src={image} alt="Station" className="w-full min-h-52" />
      </div>
      <div className="absolute mt-52 bg-white">
        <div
          className="bg-blue-600 text-white p-4 flex z-50 rounded-b-2xl -mt-1 sticky top-16"
          style={{ backgroundColor: "#0244CA" }}
        >
          <p className="mt-1 w-9 text-2xl">
            <IoLocationSharp />
          </p>
          <div className="">
            <h2 className="text-lg font-bold">Station ID - UZX67CH0943</h2>
            <p className="font-work-sans text-sm font-light">
              21 Avenue, Metropolitan Mall, Downtown, New York City
            </p>
          </div>
        </div>
        <div className=" mt-4 mb-4 flex w-full flex-col items-center gap-4 px-4">
          <div className="bg-custom-gray flex justify-between w-full gap-4 p-4 rounded-2xl">
            <div className="flex flex-col items-center">
              <img src={logo} className="mb-2 w-6" alt="Restroom" />
              <span>Restroom</span>
            </div>
            <div className="flex flex-col items-center">
              <img src={logo2} className="mb-2 w-6" alt="Diner" />
              <span>Diner</span>
            </div>
            <div className="flex flex-col items-center">
              <img src={logo3} className="mb-2 w-6" alt="Hospital" />
              <span>Hospital</span>
            </div>
            <div className="flex flex-col items-center">
              <img src={logo4} className="mb-2 w-6" alt="Kids Park" />
              <span>Kids Park</span>
            </div>
          </div>
        </div>
        {/* <div className="mt-4 flex flex-row gap-6 w-10/12 justify-between ml-7"> */}
        <div className=" mb-4 px-4 grid grid-cols-2 gap-4 justify-items-center">
          {connectors.map((connector, index) => (
            <div
              key={index}
              className={`flex items-center flex-col  w-full h-64 bg-gray-200 cursor-pointer rounded-2xl border-1 border ${connector.status === "Faulted" ? "border-red-600 " : ""}`}
              style={{
                backgroundColor: "rgba(245, 245, 245, 1)",
                borderRadius: "8px",
                // opacity: connector.available ? 1 : 0.6,
              }}
              onClick={() => openModal(connector)}
            >
              {connector.status === "avalable" ? (
                <div className="w-full mt-4"></div>
              ) : (
                <div className="w-full -ml-5">
                  <div className=" flex justify-start">
                    <h2
                      className={`mx-1 mt-1 text-xs ${connector.status === "Faulted" ? "bg-[#ff2a2a] " : "bg-[#191b1f]"} ${connector.status === "Fast charger" ? "bg-[#ffffff] text-[#FFDA44] border border-[#FFDA44]" : "bg-[#191b1f] text-white"}`}
                      style={{
                        backgroundImage: "./image/logo5.png",
                        padding: "2px 6px",
                        // backgroundColor: "#191b1f",
                        borderRadius: "18px",
                      }}
                    >
                      {connector.status}
                    </h2>
                  </div>
                </div>
              )}
              <div className={`flex flex-col items-center mx-4  ${connector.available ? "border-red-600 " : "opacity-50"}`}>
                <img
                  src={img1}
                  alt="Connector"
                  className="w-16 h-16 mb-4 "
                  style={{
                    height: "76px",
                    width: "auto",
                    padding: "8px 0px",
                  }}
                />
                <h2
                  style={{
                    height: "21px",
                    width: "100%",
                    fontWeight: "700",
                    fontSize: "18px",
                    lineHeight: "21px",
                    textAlign: "center",
                  }}
                >
                  {connector.type}
                </h2>
                <p
                  style={{
                    height: "16px",
                    width: "100%",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "16.42px",
                    textAlign: "center",
                  }}
                >
                  {connector.price}
                </p>
                <p
                  className="slot max-w-max text-sm"
                  style={{
                    padding: "2px 4px 2px 4px",
                    color: "black",
                    width: "100%",
                    fontWeight: "400",
                    fontSize: "12px",
                    lineHeight: "16.42px",
                    textAlign: "center",
                    backgroundColor: connector.available
                      ? "rgba(60, 185, 80, 1)"
                      : "rgb(60 185 80 / 70%)",
                    borderRadius: "8px",
                  }}
                >
                  {connector.slot}
                </p>
              </div>
              <div className={`flex flex-col justify-end h-full w-full text-center bg-custom-gray mb-4  ${connector.available ? "border-red-600 " : "opacity-50"}`}>
                {/* <div className="flex justify-between"> */}
                <p
                  style={{
                    height: "26px",
                    border: "0px, 0px, 1px, 0px",
                    padding: "6px, 16px, 6px, 16px",
                    // width: "200%",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "16.42px",
                    textAlign: "center",
                  }}
                >
                  {connector.sessionFee1} {connector.sessionFee}
                </p>
                <div className="h-px w-full bg-zinc-300"></div>
                {/* </div> */}
                <p>{connector.parkingFee}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      {selectedConnector && (
        <ModalComponent
          isModalOpen={!!selectedConnector}
          closeModal={closeModal}
          connector={selectedConnector}
        />
      )}
    </>
  );
};

export default Home;
