import React from "react";
import ChargeComlete from "../components/ChargeComlete";
import ToPay from "../components/ToPay";
import SessionPay from "../components/SessionPay";
import ParkingFees from "../components/ParkingFess";
import PopUp from "../components/PopUp";
import image1 from '../image/image1.png';
import { IoLocationSharp } from "react-icons/io5";

const ChargeEnd = () => {
  return (
    <>
      <header className="sticky-header fixed top-0 w-full h-[64px] gap-4 bg-accent-5 text-white flex items-center justify-center z-50">
        <h1 className="text-2xl w-full text-center font-bold pl-5">
          Session Details
        </h1>
        <img className="h-6 w-[10%] mr-4" src={image1} alt="logo" />
      </header>

      <div
        className="bg-blue-600 text-white p-4 flex z-50 rounded-b-2xl top-16 mt-[64px]"
        style={{ backgroundColor: "#0244CA" }}
      >
        <p className="mt-1 w-9 text-2xl">
          <IoLocationSharp />
        </p>
        <div className="">
          <h2 className="text-lg font-bold">Station ID - UZX67CH0943</h2>
          <p className="font-work-sans text-sm font-light">
            21 Avenue, Metropolitan Mall, Downtown, New York City
          </p>
        </div>
      </div>
      <div className="data">
        <ChargeComlete />
        <ToPay />
        <SessionPay />
        <ParkingFees />
      </div>
      <PopUp />
    </>
  );
};

export default ChargeEnd;
