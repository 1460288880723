import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import "./App.css";
// import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Payment from "./pages/payment";
import ConfirmCharger from "./pages/ConfirmCharger";
import Charging from "./pages/Charging";
import ChargeEnd from "./pages/ChargeEnd";

const App = () => {
  return (
    <div className="max-w-md mx-auto">
      <Router>
        <Routes>
          <Route element={<SidebarLayout />}>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/payment" element={<Payment />} />
            <Route exact path="/confirm" element={<ConfirmCharger />} />
            <Route exact path="/charging" element={<Charging />} />
            <Route exact path="/charge-end" element={<ChargeEnd />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
};

const SidebarLayout = () => (
  <>
    {/* <Navbar /> */}
    {/* <div className="mt-[64px]"> */}
      <Outlet />
    {/* </div> */}
  </>
);

export default App;
