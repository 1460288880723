import React from 'react'

function PopUp() {
  return (
    <div className='finish'>
      <p>Please unplug the connector to complete your session and to avoid further charges.</p>
    </div>
  )
}

export default PopUp
