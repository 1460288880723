import React from "react";
import pic from "../image/image_2024_06_14T06_36_40_151Z.png";
import visa from "../image/visa.png";
import nmex from "../image/nmex.png";
import redorange from "../image/red orange.png";
import blue from "../image/blue.png";
import image1 from "../image/image1.png";
// import { GoArrowLeft } from "react-icons/go";
// import { MdLocationOn } from "react-icons/md";
import "./PaymentCard.css";
import "./payment.css";
import { IoLocationSharp } from "react-icons/io5";
import { Link } from "react-router-dom";

function Payment() {
  return (
    <div>
      {/* Main Header */}
      <header className="sticky-header fixed top-0 w-full h-[64px] gap-4 bg-accent-5 text-white flex items-center justify-center z-50">
        <h1 className="text-xl w-full text-center font-bold pl-5">
          Enter Payment Details
        </h1>
        <img className="h-6 w-[10%] mr-4" src={image1} alt="logo" />
      </header>

      <div
        className="bg-blue-600 text-white p-4 flex z-50 rounded-b-2xl top-16 mt-[64px]"
        style={{ backgroundColor: "#0244CA" }}
      >
        <p className="mt-1 w-9 text-2xl">
          <IoLocationSharp />
        </p>
        <div className="">
          <h2 className="text-lg font-bold">Station ID - UZX67CH0943</h2>
          <p className="font-work-sans text-sm font-light">
            21 Avenue, Metropolitan Mall, Downtown, New York City
          </p>
        </div>
      </div>

      {/* Payment Form */}
      <div className="mx-5 my-5 py-2 rounded-lg md:my-8 FormCard">
        <div className="cardParent">
          {/* <div className="cardContainer flex items-center">
    <img src={pic} alt="Card" className=" flex-none cardPic" />
    <h3 className="ml-1 font-normal Card">Credit/Debit Card</h3>
  </div> */}
          <div className="cardContainer">
            <img src={pic} alt="Card" className=" cardPic" />
            <div>
              <h3 className="Card">Credit/Debit Card</h3>
            </div>
          </div>
          <div className="imageContainer flex items-center pl-3">
            <img
              src={visa}
              alt="Visa"
              className="mr-1  md:w-10 md:h-9 cardPic1"
            />
            <img
              src={nmex}
              alt="NMEX"
              className="mr-1  md:w-10 md:h-8 cardPic2"
            />
            <img
              src={redorange}
              alt="Red Orange"
              className="mr-1 md:w-10 md:h-8 cardPic3"
            />
            <img
              src={blue}
              alt="Blue"
              className="cardPic4"
              style={{ width: "17px", height: "17px" }}
            />
          </div>
        </div>
        <div className="cardContainer-2">
          <div className="InputCard">
            <input
              type="text"
              placeholder="Cardholder’s Name"
              className="Cardinput placeholder:font-normal"
            />
          </div>
          <div className="InputCard">
            <input
              type="text"
              placeholder="Card Number"
              className="Cardinput placeholder:font-normal mt-1"
            />
          </div>
          <div className="inputcard2 mt-3">
            <div className="InputCard3">
              <input
                type="text"
                placeholder="Valid Till"
                className="Cardinput2"
              />
            </div>
            <div className="InputCard3">
              <input type="text" placeholder="CVV" className="Cardinput2" />
            </div>
          </div>

          <div className="InputCard3 mt-3 ml-1">
            <input
              type="text"
              placeholder="ZIP Code"
              className="Cardinput placeholder:font-norma"
            />
          </div>
        </div>
      </div>

      {/* Contact Form */}
      <form className="text-center emailInput">
        <input
          type="email"
          placeholder="Email Address"
          required
          className="inputemail border-2 w-[85%]  rounded-md px-5 md:h-[55px]"
        />
        <br />
        <input
          type="number"
          placeholder="Phone (Optional)"
          required
          className="inputPhone w-[85%] h-10 rounded-md px-5 md:h-[55px]"
        />

        <div className="flex justify-center items-center notification">
          <h2 className="font-bold text-[16px] md:ml-24">
            Receive SMS Notifications*
            <label className="inline-flex items-center cursor-pointer ml-3">
              <input type="checkbox" className="sr-only peer" />
              <div className="relative w-8 h-4 mt-3 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer dark:bg-gray-400 peer-checked:after:translate-x-full peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            </label>
          </h2>
        </div>
        <p className="text-gray-400 w-60 text-[12px] ml-6 md:ml-[120px]">
          *Data charges may apply
        </p>

        <div className="p-2 m-2 btn-text">
          <Link to={"/confirm"}>
            <button className="my-4 text-xl bg-blue-600 text-white p-2 w-[85%] rounded-sm ">
              Proceed
            </button>
          </Link>
        </div>
      </form>
    </div>
  );
}

export default Payment;
